import product from '@/components/product/index.vue'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "payment-error",
  components:{
    product
  },
  computed:{
    ...mapGetters({
    })
  },
  created() {
  },
  methods:{
    ...mapActions({
    })
  }
}