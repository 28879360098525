import {mapMutations, mapGetters, mapActions} from 'vuex';
import router from "@/router";

export default {
  props: ['item'],
  name: "product",
  data() {
    return {
      currencyIco: '',
      like: false
    }
  },
  computed: {
    ...mapGetters({
      basket: `basket/basket`,
      addedToBasket: `basket/addedToBasket`,
      count: `basket/count`,
      isAuthenticated: `auth/isAuthenticated`,
      currency: 'setting/currentCurrency'
    }),
  },
  created() {
    this.checkCurrency()
  },
  methods: {
    ...mapMutations({
      addBasket: `basket/ADD_ITEM_TO_BASKET`,
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
    }),
    ...mapActions({
      addFavourite: `favorites/ADD_PRODUCT_TO_FAVORITE`,
      removeItemFromFavourites: `favorites/REMOVE_PRODUCT_IN_FAVORITE`,
    }),
    navigationTo() {
      this.$router.push({name: 'profile-favorite'}).catch(() => {
        console.log('')
      })
    },
    checkCurrency() {
      if (localStorage.getItem('currency') === 'USD')
        this.currencyIco = '$'
      else if (localStorage.getItem('currency') === 'JPY')
        this.currencyIco = '¥'
      else
        this.currencyIco = '₽'
    },
    addToBasket(product) {
      this.addBasket({
        select_count: this.count,
        ...product
      });
      if(this.addedToBasket) {
        this.$toasted.success(this.$t('successAddBasket'));
      } else {
        this.$toasted.error(this.$t('limitProduct'));
      }
    },
    addFavorite(product) {
      let obj = {}
      obj.product_id = product
      if (this.isAuthenticated) {
        this.addFavourite(obj).then(() => {
          this.$toasted.success(this.$t('successAddFavourites'));
          this.like = true
        }).catch(error => {
          console.log(error);
          if (error.response.status === 422) {
            console.log(this.validationErrors);
          }
        });
      } else {
        this.changePopupLogin(true);
      }
    },
    deleteFavorite(product) {
      let obj = {}
      obj.product_id = product
      console.log(obj)
      this.removeItemFromFavourites(obj).then(()=>{
        this.$toasted.success(this.$t('successDeletedFavourites'))
        this.like = false
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {
          console.log(this.validationErrors);
        }
      });
    },
    redirect() {
      if (this.$route.name === 'catalog') {
        router.push({name: 'card', params: {item: this.item.slug}}).catch(() => {
          console.log()
        });
      } else {
        router.push({name: 'card-redirect', params: {item: this.item.slug}}).catch(() => {
          console.log()
        });
      }

    }
  }
}